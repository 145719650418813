import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import useTranslations from "../hooks/useTranslations";
import { ModalContext } from "../context/ModalContext";
import { OrganizationsContext } from "../context/OrganizationsContext";
import { ConversationsContext } from "../context/ConversationsContext";
import ConversationList from "../components/conversations/ConversationList";
import ConversationForm from "../components/conversations/ConversationForm";
import ConversationUpgrade from "../components/conversations/ConversationUpgrade";
import ConversationTagCard from "../components/conversations/ConversationTagCard";
import ConversationsActions from "../components/conversations/ConversationsActions";

const Conversations = ({ tag }) => {
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [filter, setFilter] = useState("");
  const [viewArchived, setViewArchived] = useState(false);

  const { setSelected } = useContext(MenuContext);
  const { organization } = useContext(OrganizationsContext);

  const translations = useTranslations();
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const {
    createConversation,
    getConversations,
    conversation,
    getAllTags,
    tags,
  } = useContext(ConversationsContext);

  const isTagsPath = window.location.pathname.includes("/conversation/tags/");
  const isNotRootTagsPath = window.location.pathname !== "/conversation/tags";

  useEffect(() => {
    if (isTagsPath && tag) {
      getConversations({
        query,
        sortBy,
        tags: tag,
        archived: viewArchived,
        organization_id: organization?.organization_id,
      });
    } else if (!isTagsPath) {
      getConversations({
        query,
        sortBy,
        archived: viewArchived,
        organization_id: organization?.organization_id,
      });
      getAllTags({ query });
      setSelected("Chats");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, viewArchived, tag, organization, sortBy]);

  useEffect(() => {
    getAllTags({ query });
    if (conversation?.archived === false) {
      setViewArchived(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  const handleCreateConversation = () => {
    createConversation();
    modalComponent(
      translations.conversations.add,
      <ConversationForm handleCancel={clearModal} />
    );
  };

  const renderConversations = () => {
    if (!user.has_access) {
      return <ConversationUpgrade />;
    }

    if (isTagsPath && isNotRootTagsPath) {
      return <ConversationList />;
    }

    if (Array.isArray(tags) && filter === "tags") {
      return tags.map((tag) => <ConversationTagCard key={tag} tag={tag} />);
    }

    return <ConversationList />;
  };

  return (
    <div className="container-fluid card position-relative h-100">
      <div className="row">
        <div className="col-12 px-0">
          <h1 
            style={{width: 'max-content'}} 
            className="h2 text-capitalize text-gradient ms-1 mb-3"
          >
            {translations.conversations.title}
          </h1>
        </div>
      </div>
      
      <div className="row align-items-center">
        <div className="col-8 col-md-6 px-0 mb-3">
          <input
            type="text"
            value={query}
            className="form-control"
            placeholder={translations.conversations.search}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-4 pe-2 d-block d-md-none mb-3 text-end">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Archived"
            className={`btn btn-sm me-1 ${
              viewArchived ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setViewArchived(!viewArchived)}
          >
            <i className="fa fa-archive"></i>
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={handleCreateConversation}
          >
            +{" "}
            <span className="hide-mobile">
              {translations.conversations.button}
            </span>
          </button>
        </div>
        <div className="col-12 col-md-6 ps-md-3 ps-0 mb-3 pe-0 text-end">
          <ConversationsActions
            filter={filter}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setFilter={setFilter}
            viewArchived={viewArchived}
            setViewArchived={setViewArchived}
            isTagsPath={isTagsPath}
          />
        </div>
      </div>
      <div style={{ overflowY: "auto" }}>{renderConversations()}</div>
    </div>
  );
};

export default Conversations;
